import React from "react";
import styled from "styled-components";
import WhiteLogoSVG from "../../images/logo/nearshoreWhiteLogo.svg";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ContactsInfo from "../molecules/contactsInfo";
import SocialMedia from "../molecules/socialMedia";

const FooterWrapper = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
   padding: 64px 0;
   background-color: var(--dark-bg);
`;

const FooterContent = styled.div`
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   max-width: var(--screen-max-width);
   margin: var(--screen-margin);
   color: var(--text-inverted);
   gap: 200px;

   .right-container {
      display: flex;
      gap: 40px;
   }

   @media only screen and (max-width: 600px) {
      flex-direction: column-reverse;
      gap: 48px;

      .right-container {
         flex-direction: column;
         gap: 40px;
         width: 100%;
      }
   }
`;

const LegalInfoContainer = styled.div`
   display: flex;
   flex-direction: column;
   width: 400px;
   gap: 20px;
   flex-shrink: 0;

   .legalInfo1 {
      color: var(--text-muted-inverted);
   }

   .legalInfo2 {
      font-size: 12px;
      line-height: 19px;
   }

   .legalInfo3 {
      font-size: 12px;
      line-height: 19px;
      text-decoration: underline;
      cursor: pointer;
      color: var(--text-inverted);
   }

   .pme-logos-wrapper {
      display: flex;
      gap: 16px;
   }

   .pme-logos-wrapper img {
      width: 36px;
      height: 36px;
   }

   @media only screen and (max-width: 600px) {
      width: 100%;
   }
`;

const Footer = ({ isMobile }) => {
   return (
      <FooterWrapper>
         <FooterContent>
            <LegalInfoContainer>
               {!isMobile && (
                  <>
                     <WhiteLogoSVG />
                     <p className="legalInfo1">
                        We create technological solutions in the areas of
                        mobile, desktop, web, and automotive, with over 20 years
                        of experience in developing for various market segments.
                     </p>
                  </>
               )}
               <div className="pme-logos-wrapper">
                  <StaticImage
                     src="../../images/logo/pme-excelencia.png"
                     alt="PME excelência"
                  />
                  <StaticImage
                     src="../../images/logo/pme-lider.png"
                     alt="PME líder"
                  />
               </div>
               <p className="legalInfo2">
               {new Date().getFullYear()}. All rights reserved. Caixa Mágica Software
               </p>
               <Link to="/privacy-policy">
                  <p className="legalInfo3">Privacy Policy</p>
               </Link>
            </LegalInfoContainer>
            <div className="right-container">
               <ContactsInfo sectionTitle="Contact us" titleGap="32px" />
               <SocialMedia />
            </div>
         </FooterContent>
      </FooterWrapper>
   );
};

export default Footer;
