import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button, { ButtonTypes, ButtonVariants } from "./atoms/button";
import WhiteLogoSVG from "../images/logo/nearshoreWhiteLogo.svg";
import PrimaryLogoSVG from "../images/logo/nearshorePrimaryLogo.svg";
import HamburguerMenu from "../images/symbols/hamburguerMenu.svg";
import CloseSVG from "../images/symbols/close.svg";
import ArrowUpRightSVG from "../images/symbols/arrowUpRight.svg";
import { useLocation } from "@reach/router";

const NavWrapper = styled.div`
   display: flex;
   justify-content: center;
   width: 100%;
   height: 88px;
   position: fixed;
   z-index: 100;
   background: ${(props) =>
      props.fixedNavBar
         ? "var(--gradient-bg)"
         : props.isScrolled
         ? "var(--light-bg)"
         : "transparent"};
   transition: background-color 0.1s ease;
`;

const NavInnerWrapper = styled.div`
   width: 100%;
   max-width: var(--screen-max-width);
   margin: var(--screen-margin);
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
`;

const StyledLink = styled((props) => <Link {...props} />)`
   color: ${(props) => (props.active ? "var(--accent)" : "var(--text)")};
   text-decoration: none;
   font-size: 1.8rem;
   font-weight: 500;
`;

const NavLinksWrappper = styled.div`
   display: flex;
   align-items: center;
   gap: 36px;
`;

const OpenCloseWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   width: 44px;
   height: 44px;

   svg {
      color: ${(props) =>
         props.isScrolled ? "var(--text-primary)" : "var(--text-inverted)"};
   }
`;

const MobileMenuWrapper = styled.div`
   position: fixed;
   top: 0;
   left: 0;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   width: 100%;
   height: 100vh;
   overflow: hidden;
   background-color: var(--light-bg);
   transform: ${(props) =>
      props.isOpen ? "translateX(0)" : "translateX(-100%)"};
   transition: transform 0.3s ease-in-out;

   .topMobileMenu {
      display: flex;
      align-items: center;
      padding: 0 16px;
      justify-content: space-between;
      height: 88px;
   }

   .linksMobileMenu {
      padding: 0 32px;
      display: flex;
      flex-direction: column;
      gap: 36px;
   }

   .linksMobileMenu a {
      text-decoration: none;
   }

   .bottomMobileMenu {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;
   }
`;

const MobileLinkWrapper = styled.div`
   display: flex;

   p {
      color: var(--text-primary);
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
   }

   &.link-active p {
      text-decoration: underline;
      color: var(--accent-primary);
   }

   svg {
      color: var(--text-primary);
   }
`;

const MobileLink = ({ content, url, isExternalLink, onClick, isActive }) => {
   return isExternalLink ? (
      <a
         href={url}
         target="_blank"
         rel="noopener noreferrer"
         onClick={onClick}
         className={isActive ? "link-active" : ""}
      >
         <MobileLinkWrapper>
            <p>{content}</p>
            <ArrowUpRightSVG />
         </MobileLinkWrapper>
      </a>
   ) : (
      <Link to={url} onClick={onClick}>
         <MobileLinkWrapper className={isActive ? "link-active" : ""}>
            <p>{content}</p>
         </MobileLinkWrapper>
      </Link>
   );
};

const NavBar = ({ isMobile }) => {
   const [isScrolled, setIsScrolled] = useState(false);
   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
   const [activeLink, setActiveLink] = useState(null);
   const [isMounted, setIsMounted] = useState(false);
   const { pathname } = useLocation();
   const fixedNavBar =
      pathname.startsWith("/hirewise") ||
      pathname.startsWith("/privacy-policy") ||
      pathname.startsWith("/404");

   useEffect(() => {
      setIsMounted(true);
      const handleScroll = () => {
         if (window.scrollY > 100) {
            setIsScrolled(true);
         } else {
            setIsScrolled(false);
         }
      };

      if (!fixedNavBar) window.addEventListener("scroll", handleScroll);

      // Cleanup event listener on unmount
      return () => {
         window.removeEventListener("scroll", handleScroll);
      };
   }, []);

   useEffect(() => {
      setActiveLink(undefined);

      // Toggle body scroll when mobile menu is open
      if (isMobileMenuOpen) {
         document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "auto";
      }

      // Cleanup on component unmount
      return () => {
         document.body.style.overflow = "auto";
      };
   }, [isMobileMenuOpen]);

   const handleMobileLinkClick = (index) => {
      setActiveLink(index);
      setIsMobileMenuOpen(false);
   };

   return (
      <NavWrapper isScrolled={isScrolled} fixedNavBar={fixedNavBar}>
         <NavInnerWrapper>
            <StyledLink to="/">
               {isScrolled ? <PrimaryLogoSVG /> : <WhiteLogoSVG />}
            </StyledLink>
            {!isMobile && (
               <NavLinksWrappper>
                  <Button
                     content="Success Stories"
                     type={ButtonTypes.LINK}
                     includeArrowLink
                     url="https://caixamagica.pt/case-studies/"
                     variant={isScrolled ? ButtonVariants.BLACK : ""}
                  />
                  <Button
                     content="HireWise Calculator"
                     type={ButtonTypes.LINK}
                     url="/hirewise"
                     variant={isScrolled ? ButtonVariants.BLACK : ""}
                  />
                  <Button
                     content="F.A.Q."
                     type={ButtonTypes.LINK}
                     url={"/faqs"}
                     variant={isScrolled ? ButtonVariants.BLACK : ""}
                  />
                  <Button
                     content="Contact Us"
                     type={ButtonTypes.PRIMARY}
                     url={"/contact-us"}
                  />
               </NavLinksWrappper>
            )}
            {isMobile && (
               <OpenCloseWrapper isScrolled={isScrolled}>
                  <HamburguerMenu onClick={() => setIsMobileMenuOpen(true)} />
               </OpenCloseWrapper>
            )}
            {isMounted && (
               <MobileMenuWrapper isOpen={isMobileMenuOpen}>
                  <div className="topMobileMenu">
                     <PrimaryLogoSVG />
                     <OpenCloseWrapper>
                        <CloseSVG onClick={() => setIsMobileMenuOpen(false)} />
                     </OpenCloseWrapper>
                  </div>
                  <div className="linksMobileMenu">
                     <MobileLink
                        content="Home"
                        isActive={activeLink === 0}
                        onClick={() => handleMobileLinkClick(0)}
                        url={"/"}
                     />
                     <MobileLink
                        content="Success Stories"
                        isExternalLink
                        isActive={activeLink === 1}
                        onClick={() => handleMobileLinkClick(1)}
                        url="https://caixamagica.pt/case-studies/"
                     />
                     <MobileLink
                        content="HireWise Calculator"
                        isActive={activeLink === 2}
                        onClick={() => handleMobileLinkClick(2)}
                        url="/hirewise"
                     />
                     <MobileLink
                        content="F.A.Q."
                        isActive={activeLink === 4}
                        onClick={() => handleMobileLinkClick(4)}
                        url="/faqs"
                     />
                     <MobileLink
                        content="Contact us"
                        isActive={activeLink === 3}
                        onClick={() => handleMobileLinkClick(3)}
                        url="/contact-us"
                     />
                  </div>
                  <div className="bottomMobileMenu">
                     <p>
                        Powered by <strong>Caixa Mágica Software</strong>
                     </p>
                  </div>
               </MobileMenuWrapper>
            )}
         </NavInnerWrapper>
      </NavWrapper>
   );
};

export default NavBar;
